<template>
  <div class="movie-app__container movie-app__pt-4 movie-app__pb-4">
    <div class="movie-app__row">
      <div class="movie-app__col-xs-12">
        <router-link :to="{ name: 'home'}">
          <div class="movie-app__link movie-app__linkback">
            <span class="movie-app__svg_back">
              <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 7.28951L1.19884 3.99941L4 0.710494L3.39203 0L0 4.00059L3.39203 8L4 7.28951Z" fill="#29293A"/>
              </svg>
              Zurück
            </span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
  <div class="movie-app__container movie-app__pb-4">
    <div class="movie-app__row">
      <div class="movie-app__col-xs-12 movie-app__cinema-name">
        <h1>{{ cinema.name }}</h1>
      </div>
      <div class="movie-app__col-xs-12 movie-app__cinema-address">
        <a class="movie-app__link movie-app__linkcinemaadress" :href="'http://maps.google.com/?q=' + cinema.name + ',' + cinema.address + ',' + cinema.place + ',' +cinema.zip" target="_blank">
          <span class="movie-app__svg">
            <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.00008 0.166504C2.77508 0.166504 0.166748 2.77484 0.166748 5.99984C0.166748 9.47484 3.85008 14.2665 5.36675 16.0915C5.70008 16.4915 6.30841 16.4915 6.64175 16.0915C8.15008 14.2665 11.8334 9.47484 11.8334 5.99984C11.8334 2.77484 9.22508 0.166504 6.00008 0.166504ZM6.00008 8.08317C4.85008 8.08317 3.91675 7.14984 3.91675 5.99984C3.91675 4.84984 4.85008 3.9165 6.00008 3.9165C7.15008 3.9165 8.08341 4.84984 8.08341 5.99984C8.08341 7.14984 7.15008 8.08317 6.00008 8.08317Z" fill="#29293A"/>
            </svg>
            <b>{{ cinema.address }}, {{ cinema.zip }} {{ cinema.place }}</b>
          </span>
        </a>
      </div>
      <div class="movie-app__col-xs-12 movie-app__pt-4 movie-app__cinema-link">
        <a class="movie-app__link" style="margin-left:18px;"  :href="cinema.website" target="_blank">
            Zur Programmseite des Kinos  
        </a>
      </div>
    </div>
  </div>
    <div class="movie-app__container movie-app__pt-4">
        <div class="movie-app__row">
          <div class="movie-app__col-xs-12 movie-app__col-sm-6 movie-app__pb-4">
              <fieldset class="movie-app__search-fieldset">
                  <input type="text" v-model="search" placeholder="Film suchen" aria-label="Suchbegriff" name="search" class="movie-app__search-input">
                  <div class="css-movie-app__search-buttons">
                      <button v-show="search" v-on:click="search = ''" type="reset" class="movie-app__search-delete">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#29293a" aria-hidden="false" height="16" stroke="#29293a" class="movie-app__search-delete-svg"><title>Suche zurücksetzen</title><path d="M1.3 1.3l13.4 13.4M1.3 14.7L14.7 1.3" stroke-width="1.5" stroke-linecap="square"></path></svg>
                      </button>
                      <button class="movie-app__search-search">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#29293a" aria-hidden="false" height="16" class="movie-app__search-search-svg"><title>Suche absenden</title><path d="M6.2195 0C2.785 0 0 2.7845 0 6.2186c0 3.4341 2.7849 6.2186 6.2196 6.2186 1.3207 0 2.5456-.4119 3.5524-1.1135l4.6128 4.6113 1.5526-1.5525-4.612-4.6112a6.195 6.195 0 001.1137-3.5527C12.439 2.7845 9.654 0 6.2195 0zm-.092 10.8822c-2.6268 0-4.756-2.129-4.756-4.7554s2.1292-4.7554 4.756-4.7554c2.6269 0 4.7562 2.129 4.7562 4.7554 0 2.6265-2.1293 4.7554-4.7561 4.7554z"></path></svg>
                      </button>
                  </div>
              </fieldset>
          </div>
          <div class="movie-app__col-xs-12 movie-app__col-sm-6 movie-app__pb-4">
            <select v-model="selectedDay" @change="selectDay()" class="movie-app__form-control">
                <option value="" selected>Datum wählen</option>
                <option v-for="product in products" :value="product.id" :key="product.id">{{ product.name }}</option>
            </select>
          </div>
          <div class="movie-app__col-xs-12 movie-app__pb-4">
              <button @click="actDay()" class="movie-app__btn movie-app__mr-2" :class="{ active: aktDayButton}">Heute</button> 
              <button @click="allData()" class="movie-app__btn" :class="{ active: !selectedDay && !aktDayButton}">Aktuelle Woche</button>
          </div>
        </div>
        <div class="movie-app__row">
            <div v-if="movieFound" class="movie-app__col-xs-12 movie-app__pt-4 movie-app__pb-4">
                <p><b>Vorführungen {{ dayName }} </b></p>
                <p class="movie-app__info-text-time">Die Spielzeiten für die kommende Woche erscheinen jeweils am Mittwoch.</p>
            </div>
        </div>
    </div>
  <div class="movie-app__container">
    <div v-for="item in filteredList" :key="item.movie_id" class="movie-app__row movie-app__cinema-item">
      <div class="movie-app__col-xs-4 movie-app__col-sm-3 movie-app__col-md-2">
          <img :src="item.moviePoster" class="movie-app__movie-image" style=":black;"/>
      </div>
      <div class="movie-app__col-xs-8 movie-app__col-sm-9 movie-app__col-md-10">
          <h4 class="movie-app__list-name">{{ item.title }} {{ item.ov }}</h4>
          <p class="movie-app__list-address movie-app__mt-2">
              <span v-if="item.genre">{{ item.genre }} | </span><span v-if="item.fsk">FSK: {{ item.fsk }} | </span><span v-if="item.start">Neustart: {{ item.start }}</span>
          </p>
          <p>
            <router-link :to="{ name: 'movie', params: { id: item.movie_id, pageTitle: item.title }}" class="movie-app__link">
              Alle Infos zum Film
            </router-link>
          </p>
      </div>
      <div class="movie-app__col-xs-12 movie-app__pb-4 movie-app__pt-4">
        <Playtimes :playtimes="item.playtimes" :key="componentKey"/>
      </div>
    </div>
    <div class="movie-app__row"  v-if="!movieFound">
      <div class="movie-app__col-xs-12 movie-app__pt-5">
          <h2>Das Kino zeigt heute keine Vorstellungen.</h2>
      </div>
    </div>
  </div>

</template>

<script>
import Tab from '../components/Tab.vue'
import TabsWrapperDynamic from '../components/TabsWrapperDynamic.vue'
import Playtimes from '../components/Playtimes.vue'

export default {
  name: 'WrapAround',
  components: { Tab, TabsWrapperDynamic, Playtimes},
  props: ['id'],
  data() {
    return {
      componentKey: 0,
      selectedCinema: '',
      wrapperDynamicKey: 0,
      search: '',
      selectedDay: new Date().getDay(),
      aktDayButton: new Date().getDay(),
      aktDay: new Date().getDay(),
      cinema: [],
      movieList: [],
      countMovies: 0,
      dayName: '',
      movieFound: true,
      products: [
                {id: '4', name: 'Do. 27.03.', date: 'Do. 27.03.'},
                {id: '5', name: 'Fr. 28.02.', date: 'Fr. 28.02.'},
                {id: '6', name: 'Sa. 29.03.', date: 'Sa. 29.03.'},
                {id: '0', name: 'So. 20.03.', date: 'So. 20.03.'},
                {id: '1', name: 'Mo. 31.03.', date: 'Mo. 31.03.'},
                {id: '2', name: 'Di. 01.04.', date: 'Di. 01.04.'},
                {id: '3', name: 'Mi. 02.04.', date: 'Mi. 02.04.'},
      ],
    }
  },
  methods: {
    async getData() {
        await fetch("https://interface2.datenlieferung-streamingdienste.de/api/entry/cinemaById/" + this.id)
            .then(res => res.json())
            .then(data => this.cinema = data)
            .catch(err => console.log(err.message));
    },
    async getDataMovie() {
        this.movieFound = true
        await fetch("https://interface2.datenlieferung-streamingdienste.de/api/entry/movieByCinema/"+ this.id + this.selectedDay)
            .then(res => res.json())
            .then(data => this.movieList = data)
            .catch(err => console.log(err.message));
        this.countMovies = Object.keys(this.movieList).length
        this.wrapperDynamicKey += 1
        if ( this.selectedDay != '' ) {
            this.dayName = 'am ' + this.products.find(x => x.id == this.selectedDay).date
        } else {
            this.dayName = 'vom ' + this.products[0].date + ' - ' + this.products[this.products.length - 1].date
        }
        this.forceRerender()
    },
    allData() {
        this.aktDayButton = ''
        this.selectedDay = ''
        localStorage.setItem('DayFilter', 'all')
        this.getDataMovie()
    },
    actDay() {
        this.aktDayButton = this.aktDay
        this.selectedDay = this.aktDay
        localStorage.setItem('DayFilter', this.selectedDay)
        this.getDataMovie()
    },
    selectDay() {
        if ( this.selectedDay == this.aktDay ) {
            this.aktDayButton = this.aktDay
        } else {
            this.aktDayButton = ''
        }
        localStorage.setItem('DayFilter', this.selectedDay)
        this.getDataMovie()
    },
    forceRerender() {
      this.componentKey += 1;
    }
  },
  mounted() {
      if( localStorage.getItem('DayFilter') ) {
          if ( localStorage.getItem('DayFilter') == 'all' ) {
              this.aktDayButton = ''
              this.selectedDay = ''
          } else {
              this.selectedDay = localStorage.getItem('DayFilter')
              if ( this.selectedDay == this.aktDay ) {
                  this.aktDayButton = this.aktDay
              } else {
                  this.aktDayButton = ''
              }
          }
        }
      this.getData(),
      this.getDataMovie()
  },
  computed: {
      filteredList() {
        const result = Object.values(this.movieList).filter(post => {
          return post.title.toLowerCase().includes(this.search.toLowerCase())
        })
        if (result.length) {
            this.movieFound = true
        } else {
            this.movieFound = false
        }
        return result
    }
  }
}
</script>