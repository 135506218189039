<template>
    <div>
        <div class="movie-app__container movie-app__pt-4">
          <div class="movie-app__row">
            <div class="movie-app__col-xs-12">
                <div class="movie-app__col-xs-12 movie-app__col-sm-9 movie-app__p-0">
                    <div class="movie-app__col-xs-12 movie-app__col-sm-9 movie-app__col-md-7 movie-app__p-0 movie-app__padding-search-20">
                        <fieldset class="movie-app__search-fieldset">
                            <input type="text" v-model="search" placeholder="Kino suchen" aria-label="Suchbegriff" name="search" class="movie-app__search-input">
                            <div class="css-movie-app__search-buttons">
                                <button v-show="search" v-on:click="search = ''" type="reset" class="movie-app__search-delete">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#29293a" aria-hidden="false" height="16" stroke="#29293a" class="movie-app__search-delete-svg"><title>Suche zurücksetzen</title><path d="M1.3 1.3l13.4 13.4M1.3 14.7L14.7 1.3" stroke-width="1.5" stroke-linecap="square"></path></svg>
                                </button>
                                <button class="movie-app__search-search">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#29293a" aria-hidden="false" height="16" class="movie-app__search-search-svg"><title>Suche absenden</title><path d="M6.2195 0C2.785 0 0 2.7845 0 6.2186c0 3.4341 2.7849 6.2186 6.2196 6.2186 1.3207 0 2.5456-.4119 3.5524-1.1135l4.6128 4.6113 1.5526-1.5525-4.612-4.6112a6.195 6.195 0 001.1137-3.5527C12.439 2.7845 9.654 0 6.2195 0zm-.092 10.8822c-2.6268 0-4.756-2.129-4.756-4.7554s2.1292-4.7554 4.756-4.7554c2.6269 0 4.7562 2.129 4.7562 4.7554 0 2.6265-2.1293 4.7554-4.7561 4.7554z"></path></svg>
                                </button>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div class="movie-app__container movie-app__pt-4">
            <div class="movie-app__row movie-app__cinema-item movie-app__gray-hover" v-for="item in filteredList" :key="item.cinema_id">
                <router-link :to="{ name: 'cinema', params: { id: item.cinema_id, pageTitle:  item.name}}">
                    <div class="movie-app__col-xs-12">
                        <div class="movie-app__col-xs-9 movie-app__p-0">
                            <div class="movie-app__row">
                                <div class="movie-app__col-xs-9 movie-app__col-md-7 movie-app__p-0">
                                    <h4 class="movie-app__list-name">{{ item.name }}</h4>
                                </div>
                                <div class="movie-app__col-xs-9 movie-app__col-md-5 movie-app__p-0">
                                    <p class="movie-app__list-address">{{ item.address }}, {{ item.zip }} {{ item.place }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-3">
                                <p class="movie-app__text-right movie-app__link">Zum Kino</p>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="movie-app__row"  v-if="!cinemaFound">
                <div class="movie-app__col-xs-12 movie-app__pt-4">
                    <h2>Es wurde kein Kino gefunden.</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default ({
    data() {
        return {
            search: '',
            searchDeleted: '',
            listItems: [],
            cinemaFound: true,
        }
    },
    methods: {
        async getData() {
            await fetch("https://interface2.datenlieferung-streamingdienste.de/api/entry/cinemaList")
                .then(res => res.json())
                .then(data => this.listItems = data)
                .catch(err => console.log(err.message));
        },
        say(message) {
            alert(message)
        }
    },
    mounted() {
        this.getData()
    },
    computed: {
        filteredList() {
            const result = this.listItems.filter(post => {
                return post.name.toLowerCase().includes(this.search.toLowerCase()) || post.address.toLowerCase().includes(this.search.toLowerCase()) || post.place.toLowerCase().includes(this.search.toLowerCase())
            })
            if (result.length) {
                this.cinemaFound = true
            } else {
                this.cinemaFound = false
            }
            return result
        }
    }
})
</script>