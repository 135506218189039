import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MovieView from '../views/MovieView.vue'
import CinemaView from '../views/CinemaView.vue'

const routes = [
  {
    path: '/kinoprogramm',
    name: 'home',
    component: HomeView,
    meta: [
      { 
        title: 'Kinoprogramm SZ.de' 
      },
      {
        property: 'og:title',
        content: 'Kinoprogramm SZ.de',
      },
      {
        property: 'og:type', 
        content: 'website' 
      },
      {
        property: 'og:description',
        content: 'Alle Kino-Neustarts mit Programm.',
      }
    ]
  },
  {
    path: '/kinoprogramm/movie/:id/:pageTitle',
    name: 'movie',
    component: MovieView,
    props: true,
    meta: {
      title: 'Film - Kinoprogramm SZ.de'
    }
  },
  {
    path: '/kinoprogramm/cinema/:id/:pageTitle',
    name: 'cinema',
    component: CinemaView,
    props: true,
    meta: {
      title: 'Kino - Kinoprogramm SZ.de'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0, behavior: 'smooth', }
  },
})


router.beforeEach((to, from, next) => {
  const titleFromParams = to.params?.pageTitle
  if (titleFromParams) {
    document.title = `${titleFromParams} - Kinoprogramm SZ.de`
  } else {
    document.title = to.meta?.title ?? 'Kinoprogramm SZ.de'
  }
  next()
})


export default router
