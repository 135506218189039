<template>
  <div class="movie-app__container movie-app__pt-4 movie-app__pb-4">
    <div class="movie-app__row">
      <div class="movie-app__col-xs-12">
        <router-link :to="{ name: 'home'}">
          <div class="movie-app__link movie-app__linkback">
            <span class="movie-app__svg_back">
              <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 7.28951L1.19884 3.99941L4 0.710494L3.39203 0L0 4.00059L3.39203 8L4 7.28951Z" fill="#29293A"/>
              </svg>
              Zurück
            </span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
  <div class="movie-app__container movie-app__pb-4">
    <div class="movie-app__row">
      <div class="movie-app__col-xs-12">
        <div class="movie-app_center-text">
          <h1 class="movie-app__page-header-article">                 
            <span class="movie-app__page-header-article__title">{{ movie.title }}</span>
          </h1>
          <p class="movie-app__page-header-article__overline">
            <span v-if="movie.genre">{{ movie.genre }} </span>
            <span v-if="movie.country"> | {{ movie.country }} ({{ movie.year }}) </span>
            <span v-if="movie.fsk"> | FSK: {{ movie.fsk }} </span>
            <span v-if="movie.length"> | Laufzeit: {{ movie.length }} </span>
            <span v-if="movie.start"> | Neustart: {{ movie.start }} </span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div v-if="movie.movie_image" class="movie-app__pt-4 movie-app__pb-4">
    <div class="movie-app_center-text-704">
      <img :src="movie.movie_image" class="movie-app__header-img"/>
    </div>
  </div>
  <div class="movie-app__container">
  <TabsWrapper>
    <Tab title="Filminfo">
      <div v-if="movie.teaser" class="movie-app__container movie-app__pt-4 movie-app__pb-4">
        <div class="movie-app__row">
          <div class="movie-app__col-xs-12">
            <div class="movie-app_center-text">
              <p class="movie-app__font_3">{{ movie.teaser }}</p>
              <p class="movie-app__font_8">Dieser Text wurde von den Kinospezialisten unserer Partneragentur verfasst.</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="movie.trailer" class="movie-app__container movie-app__pt-4 movie-app__pb-4">
        <div class="movie-app__row">
          <div class="movie-app__col-xs-12 movie-app__p-0">
            <div class="movie-app_center-text-704">
              <video :poster="videoPoster" controls>
                <source :src="movie.trailer" type=video/mp4>
              </video>
            </div>
          </div>
        </div>
      </div>
      <div class="movie-app__container movie-app__movie-data movie-app__pt-4 movie-app__pb-4">
        <div class="movie-app__col-xs-12">
          <div class="movie-app_center-text">
            <div v-if="movie.director" class="movie-app__row">
              <div class="movie-app__col-xs-4 movie-app__p-0">
                <p><b>Regie</b></p>
              </div>
              <div class="movie-app__col-xs-8 movie-app__p-0">
                <p>{{ movie.director }}</p>
              </div>
            </div>
            <div v-if="movie.actor" class="movie-app__row">
              <div class="movie-app__col-xs-4 movie-app__p-0">
                <p class="movie-app__mt-2"><b>Darsteller</b></p>
              </div>
              <div class="movie-app__col-xs-8 movie-app__p-0">
                <p class="movie-app__mt-2">{{ movie.actor }}</p>
              </div>
            </div>
            <div v-if="movie.lender" class="movie-app__row">
              <div class="movie-app__col-xs-4 movie-app__p-0">
                <p class="movie-app__mt-2"><b>Copyright</b></p>
              </div>
              <div class="movie-app__col-xs-8 movie-app__p-0">
                <p class="movie-app__mt-2">{{ movie.lender }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Tab>
    <Tab title="Vorführungen">
    <div class="movie-app__container movie-app__pt-4">
        <div class="movie-app__row">
          <div class="movie-app__col-xs-12 movie-app__col-sm-6 movie-app__pb-4">
              <fieldset class="movie-app__search-fieldset">
                  <input type="text" v-model="search" placeholder="Kino suchen" aria-label="Suchbegriff" name="search" class="movie-app__search-input">
                  <div class="css-movie-app__search-buttons">
                      <button v-show="search" v-on:click="search = ''" type="reset" class="movie-app__search-delete">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#29293a" aria-hidden="false" height="16" stroke="#29293a" class="movie-app__search-delete-svg"><title>Suche zurücksetzen</title><path d="M1.3 1.3l13.4 13.4M1.3 14.7L14.7 1.3" stroke-width="1.5" stroke-linecap="square"></path></svg>
                      </button>
                      <button class="movie-app__search-search">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#29293a" aria-hidden="false" height="16" class="movie-app__search-search-svg"><title>Suche absenden</title><path d="M6.2195 0C2.785 0 0 2.7845 0 6.2186c0 3.4341 2.7849 6.2186 6.2196 6.2186 1.3207 0 2.5456-.4119 3.5524-1.1135l4.6128 4.6113 1.5526-1.5525-4.612-4.6112a6.195 6.195 0 001.1137-3.5527C12.439 2.7845 9.654 0 6.2195 0zm-.092 10.8822c-2.6268 0-4.756-2.129-4.756-4.7554s2.1292-4.7554 4.756-4.7554c2.6269 0 4.7562 2.129 4.7562 4.7554 0 2.6265-2.1293 4.7554-4.7561 4.7554z"></path></svg>
                      </button>
                  </div>
              </fieldset>
          </div>
          <div class="movie-app__col-xs-12 movie-app__col-sm-6 movie-app__pb-4">
            <select v-model="selectedDay" @change="selectDay()" class="movie-app__form-control">
                <option value="" selected>Datum wählen</option>
                <option v-for="product in products" :value="product.id" :key="product.id">{{ product.name }}</option>
            </select>
          </div>
          <div class="movie-app__col-xs-12 movie-app__pb-4">
              <button @click="actDay()" class="movie-app__btn movie-app__mr-2" :class="{ active: aktDayButton}">Heute</button> 
              <button @click="allData()" class="movie-app__btn" :class="{ active: !selectedDay && !aktDayButton}">Aktuelle Woche</button>
          </div>
        </div>
    </div>
    <div class="movie-app__container movie-app__pt-4 movie-app__pb-4">
        <div class="movie-app__row">
            <div v-if="movieFound" class="movie-app__col-xs-12">
                <p><b>Vorführungen {{ dayName }}</b></p>
                <p class="movie-app__info-text-time">Die Spielzeiten für die kommende Woche erscheinen jeweils am Mittwoch.</p>
            </div>
            <div v-if="!movieFound" class="movie-app__col-xs-12 movie-app__pt-5">
                <h2>Es wurden keine Vorführungen {{ dayName }} gefunden.</h2>
            </div>
        </div>
    </div>
      <div class="movie-app__container">
        <div v-for="item in filteredList" :key="item.cinema_id" class="movie-app__row movie-app__cinema-item">
          <div class="movie-app__col-xs-12 movie-app__pb-4">
              <h4 class="movie-app__list-name">{{ item.name }}</h4>
              <p class="movie-app__list-address movie-app__mt-2">{{ item.address }}, {{ item.zip }} {{ item.place }}</p>
              <p class="movie-app__mt-4">
                  <a class="movie-app__link" :href="item.website" target="_blank">
                      Zur Programmseite des Kinos  
                  </a>
              </p>
          </div>
          <div class="movie-app__col-xs-12 movie-app__movieplaytimes movie-app__pt-4">
              <Playtimes :playtimes="item.playtimes" :key="componentKey"/>
          </div>
        </div>
      </div>
    </Tab>
  </TabsWrapper>
  </div>
</template>

<script>
import Tab from '../components/Tab.vue'
import TabsWrapper from '../components/TabsWrapper.vue'
import Playtimes from '../components/Playtimes.vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'
export default {
  components: { Tab, TabsWrapper, Playtimes, Carousel, Slide, Navigation},
  props: ['id'],
  data() {
    return {
      componentKey: 0,
      search: '',
      selectedDay: new Date().getDay(),
      aktDayButton: new Date().getDay(),
      aktDay: new Date().getDay(),
      wrapperDynamicKey: 0,
      countMovies: 0,
      movie: [],
      cinemaList: [],
      dayName: '',
      videoPoster: '',
      movieFound: true,
      products: [
                {id: '4', name: 'Do. 27.03.', date: 'Do. 27.03.'},
                {id: '5', name: 'Fr. 28.02.', date: 'Fr. 28.02.'},
                {id: '6', name: 'Sa. 29.03.', date: 'Sa. 29.03.'},
                {id: '0', name: 'So. 20.03.', date: 'So. 20.03.'},
                {id: '1', name: 'Mo. 31.03.', date: 'Mo. 31.03.'},
                {id: '2', name: 'Di. 01.04.', date: 'Di. 01.04.'},
                {id: '3', name: 'Mi. 02.04.', date: 'Mi. 02.04.'},
      ],
      settings: {
          itemsToShow: 1,
          snapAlign: 'center',
      }
    }
  },
  methods: {
    async getData() {
        await fetch("https://interface2.datenlieferung-streamingdienste.de/api/entry/movieById/" + this.id)
            .then(res => res.json())
            .then(data => this.movie = data)
            .catch(err => console.log(err.message));
        if ( this.movie.movie_images.length ) {
          this.videoPoster = this.movie.movie_images[this.movie.movie_images.length - 1]
        } else {
          this.videoPoster = this.movie.image
        }
        
    },
    async getDataCinema() {
        await fetch("https://interface2.datenlieferung-streamingdienste.de/api/entry/cinemaByMovie/"+ this.id + this.selectedDay)
            .then(res => res.json())
            .then(data => this.cinemaList = data)
            .catch(err => console.log(err.message));
        this.countMovies = Object.keys(this.cinemaList).length
        this.wrapperDynamicKey += 1
        if ( this.selectedDay != '' ) {
            this.dayName = 'am ' + this.products.find(x => x.id == this.selectedDay).date
        } else {
            this.dayName = 'vom ' + this.products[0].date + ' - ' + this.products[this.products.length - 1].date
        }
        this.forceRerender()
    },
    allData() {
        this.aktDayButton = ''
        this.selectedDay = ''
        localStorage.setItem('DayFilter', 'all')
        this.getDataCinema()
    },
    actDay() {
        this.aktDayButton = this.aktDay
        this.selectedDay = this.aktDay
        localStorage.setItem('DayFilter', this.selectedDay)
        this.getDataCinema()
    },
    selectDay() {
        if ( this.selectedDay == this.aktDay ) {
            this.aktDayButton = this.aktDay
        } else {
            this.aktDayButton = ''
        }
        localStorage.setItem('DayFilter', this.selectedDay)
        this.getDataCinema()
    },
    forceRerender() {
      this.componentKey += 1;
    }
  },
  mounted() {
      if( localStorage.getItem('DayFilter') ) {
        if ( localStorage.getItem('DayFilter') == 'all' ) {
          this.aktDayButton = ''
          this.selectedDay = ''
        } else {
          this.selectedDay = localStorage.getItem('DayFilter')
          if ( this.selectedDay == this.aktDay ) {
              this.aktDayButton = this.aktDay
          } else {
            this.aktDayButton = ''
          }
        }
      }
      this.getData(),
      this.getDataCinema()
  },
  computed: {
      filteredList() {
        const result = Object.values(this.cinemaList).filter(post => {
          return post.name.toLowerCase().includes(this.search.toLowerCase()) || post.address.toLowerCase().includes(this.search.toLowerCase()) || post.place.toLowerCase().includes(this.search.toLowerCase())
         })
        if (result.length) {
            this.movieFound = true
        } else {
            this.movieFound = false
        }
        return result
      }
  }
}
</script>


<style>
.carousel__item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__item img {
    max-height: 100%;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  background-color: #fff;
  border-radius: 50%;
}
</style>