<template>
  <router-view/>

  <transition name="fade">
    <div id="pagetop" class="movie-app__back-to-top" v-show="scY > 400" @click="toTop">
      <svg width="8" height="18" viewBox="0 0 8 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4.66335L6.82855 5.76783L4.80436 3.65874L4.80436 17.5L3.19564 17.5L3.19564 3.65874L1.1724 5.76783L-5.61108e-07 4.66335L4 0.5L8 4.66335Z" fill="white"/>
      </svg>
    </div>
  </transition>
</template>




<script>
export default ({
    el: '#movie-app',
    data() {
      return {
        scTimer: 0,
        scY: 0,
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
      handleScroll: function () {
        if (this.scTimer) return;
        this.scTimer = setTimeout(() => {
          this.scY = window.scrollY;
          clearTimeout(this.scTimer);
          this.scTimer = 0;
        }, 100);
      },
      toTop: function () {
        window.scrollTo({
          top: '#movie-app',
          behavior: "smooth"
        });
      },
    },
})
</script>