<template>
    <div class="movie-app__container movie-app__pt-4">
        <div class="movie-app__row">
          <div class="movie-app__col-xs-12 movie-app__col-sm-6 movie-app__pb-4">
              <fieldset class="movie-app__search-fieldset">
                  <input type="text" v-model="search" placeholder="Film suchen" aria-label="Suchbegriff" name="search" class="movie-app__search-input">
                  <div class="css-movie-app__search-buttons">
                      <button v-show="search" v-on:click="search = ''" type="reset" class="movie-app__search-delete">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#29293a" aria-hidden="false" height="16" stroke="#29293a" class="movie-app__search-delete-svg"><title>Suche zurücksetzen</title><path d="M1.3 1.3l13.4 13.4M1.3 14.7L14.7 1.3" stroke-width="1.5" stroke-linecap="square"></path></svg>
                      </button>
                      <button class="movie-app__search-search">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#29293a" aria-hidden="false" height="16" class="movie-app__search-search-svg"><title>Suche absenden</title><path d="M6.2195 0C2.785 0 0 2.7845 0 6.2186c0 3.4341 2.7849 6.2186 6.2196 6.2186 1.3207 0 2.5456-.4119 3.5524-1.1135l4.6128 4.6113 1.5526-1.5525-4.612-4.6112a6.195 6.195 0 001.1137-3.5527C12.439 2.7845 9.654 0 6.2195 0zm-.092 10.8822c-2.6268 0-4.756-2.129-4.756-4.7554s2.1292-4.7554 4.756-4.7554c2.6269 0 4.7562 2.129 4.7562 4.7554 0 2.6265-2.1293 4.7554-4.7561 4.7554z"></path></svg>
                      </button>
                  </div>
              </fieldset>
          </div>
          <div class="movie-app__col-xs-12 movie-app__col-sm-6 movie-app__pb-4">
            <select v-model="selectedDay" @change="selectDay()" class="movie-app__form-control">
                <option value="" selected>Datum wählen</option>
                <option v-for="product in products" :value="product.id" :key="product.id">{{ product.name }}</option>
            </select>
          </div>
          <div class="movie-app__col-xs-12 movie-app__pb-4">
              <button @click="actDay()" class="movie-app__btn movie-app__mr-2" :class="{ active: aktDayButton}">Heute</button> 
              <button @click="allData()" class="movie-app__btn" :class="{ active: !selectedDay && !aktDayButton}">Aktuelle Woche</button>
          </div>
        </div>
        <div class="movie-app__row">
            <div v-if="movieFound" class="movie-app__col-xs-12 movie-app__pt-4 movie-app__pb-4">
                <p><b>Vorführungen {{ dayName }} </b></p>
                <p class="movie-app__info-text-time">Die Spielzeiten für die kommende Woche erscheinen jeweils am Mittwoch.</p>
            </div>
        </div>
    </div>
    <div class="movie-app__container movie-app__pt-4">
        <div class="movie-app__row movie-app__row-flex">
            <div v-for="item in filteredList" :key="item.movie_id" class="movie-app__col-xs-6 movie-app__col-sm-4 movie-app__col-md-3 movie-app__pb-2">
                <router-link :to="{ name: 'movie', params: { id: item.movie_id, pageTitle: item.title }}" class="movie-app__link-movie-list">
                    <div class="movie-app__col-xs-12 movie-app__p-0">
                        <div class="movie-app__list-movie-image" :style="{ backgroundImage: 'url(' + item.moviePoster + ')' }"
></div>
                    </div>
                    <div class="movie-app__col-xs-12 movie-app__p-sm-0">
                        <h4 class="movie-app__list-movie-name">{{ item.title }} {{ item.ov }}</h4>
                        <p class="movie-app__list-movie-data">
                            {{ item.genre }}  <br> FSK: {{ item.fsk }}<br>
                            Neustart: {{ item.start }}
                        </p>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="movie-app__row"  v-if="!movieFound">
            <div class="movie-app__col-xs-12 movie-app__pt-4">
                <h2>Es wurden keine Vorführungen {{ dayName }} gefunden.</h2>
            </div>
        </div>
    </div>
    
</template>

<script>
export default ({
    data() {
        return {
            search: '',
            selectedDay: new Date().getDay(),
            aktDayButton: new Date().getDay(),
            aktDay: new Date().getDay(),
            listItems: [],
            dayName: '',
            movieFound: true,
            products: [
                {id: '4', name: 'Do. 27.03.', date: 'Do. 27.03.'},
                {id: '5', name: 'Fr. 28.02.', date: 'Fr. 28.02.'},
                {id: '6', name: 'Sa. 29.03.', date: 'Sa. 29.03.'},
                {id: '0', name: 'So. 20.03.', date: 'So. 20.03.'},
                {id: '1', name: 'Mo. 31.03.', date: 'Mo. 31.03.'},
                {id: '2', name: 'Di. 01.04.', date: 'Di. 01.04.'},
                {id: '3', name: 'Mi. 02.04.', date: 'Mi. 02.04.'},
            ],
        }
    },
    methods: {
        async getData() {
            await fetch("https://interface2.datenlieferung-streamingdienste.de/api/entry/movieList/1000")
                .then(res => res.json())
                .then(data => this.listItems = data)
                .catch(err => console.log(err.message));
        },
        async changeDay() {         
            await fetch("https://interface2.datenlieferung-streamingdienste.de/api/entry/movieList/" + this.selectedDay)
                .then(res => res.json())
                .then(data => this.listItems = data)
                .catch(err => console.log(err.message));
            if ( this.selectedDay != '' ) {
                this.dayName = 'am ' + this.products.find(x => x.id == this.selectedDay).date
            } else {
                this.dayName = 'vom ' + this.products[0].date + ' - ' + this.products[this.products.length - 1].date
            }  
        },
        allData() {
            this.aktDayButton = ''
            this.selectedDay = ''
            this.dayName = 'vom ' + this.products[0].date + ' - ' + this.products[this.products.length - 1].date
            localStorage.setItem('DayFilter', 'all')
            this.getData()
        },
        actDay() {
            this.aktDayButton = this.aktDay
            this.selectedDay = this.aktDay
            localStorage.setItem('DayFilter', this.selectedDay)
            this.changeDay()
        },
        selectDay() {
            if ( this.selectedDay == this.aktDay ) {
                this.aktDayButton = this.aktDay
            } else {
                this.aktDayButton = ''
            }
            localStorage.setItem('DayFilter', this.selectedDay)
            this.changeDay()
        }
    },
    mounted() {
        if( localStorage.getItem('DayFilter') ) {
            if ( localStorage.getItem('DayFilter') == 'all' ) {
                this.aktDayButton = ''
                this.selectedDay = ''
            } else {
                this.selectedDay = localStorage.getItem('DayFilter')
                if ( this.selectedDay == this.aktDay ) {
                    this.aktDayButton = this.aktDay
                } else {
                    this.aktDayButton = ''
                }
            }
        }
        this.changeDay()        
    },
    computed: {
        filteredList() {
            const result = this.listItems.filter(post => {return post.title.toLowerCase().includes(this.search.toLowerCase())})
            if (result.length) {
                this.movieFound = true
            } else {
                this.movieFound = false
            }
            return result
        }
    }
})
</script>

<style>

</style>