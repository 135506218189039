<template>
    <div class="movie-app__container movie-app__pbt-4">
        <div class="movie-app__row">
            <div class="movie-app__col-xs-12">
            <div :class="{ 'movie-app_center-text-704': selectedTitle == 'Filminfo' }">
                <ul class="movie-app__tabs__header">
                    <li 
                    v-for="tab in tabTitles" 
                    :key="tab.title"
                    :class="{ movie_app__selected: tab.title == selectedTitle }"
                    @click="getTab(tab.title)">
                        <p class="movie-app__tabs__header-text">
                            {{ tab.title }}
                        </p>
                    </li>
                </ul>
            </div>
            </div>
        </div>
    </div>
    <slot />
</template>

<script>
import { ref, provide } from 'vue'
export default {
    data() {
        return {
            selectedTitle: '',
        }
    },
    setup (props, { slots }) {
        const tabTitles = ref(slots.default().map((tab) => tab.props))
        var aktTabTitle = tabTitles.value[0].title
        var aktTab = ref(tabTitles.value[0].title)
        if( localStorage.getItem(aktTabTitle)) {
            aktTab = ref(localStorage.getItem(aktTabTitle))
        }
        const selectedTitle = aktTab

        provide("selectedTitle", selectedTitle)
        return {
            selectedTitle,
            tabTitles,
        }
    },
    methods: {
        getTab( title ) {
            if ( title == 'Kino' || title =='Filme' ) {
                localStorage.setItem('Kino', title)
            }
            this.selectedTitle = title
        }
    },
}
</script>